<template>
  <div class="footer-wrapper">
    <div class="wrapper fbc" :class="{ onlyWxCode: siteId == 5 }">
      <div class="link_area">
        <dl
          class="fcc"
          v-if="showObj[aboutId]"
        >
          <dt>关于我们</dt>
          <dd @click="jumpTo('aboutUs')">品牌介绍</dd>
          <!-- <dd @click="jumpTo('aboutUs?type=1')">品牌介绍</dd> -->
          <!-- <dd @click="jumpTo('aboutUs?type=2')">服务网点</dd> -->
          <!-- <dd @click="jumpTo('contactUs')">联系我们</dd> -->
        </dl>
      </div>
      <div
        class="link_area"
        v-if="showObj[1] || showObj[2]"
      >
        <dl>
          <dt>产品中心</dt>
          <dd
            @click="jumpTo('product')"
            v-if="showObj[1]"
          >信托产品</dd>
          <dd
            @click="jumpTo('zx_product')"
            v-if="showObj[2]"
          >政信产品</dd>
        </dl>
      </div>
      <div
        class="link_area"
        v-if="showObj[3] || showObj[4]"
      >
        <dl>
          <dt>新闻资讯</dt>
          <dd
            @click="toNews('1')"
            v-if="showObj[3]"
          >最新资讯</dd>
          <dd
            @click="toNews('2')"
            v-if="showObj[4]"
          >信托百科</dd>
        </dl>
      </div>
      <div
        class="qrcode fcc wxCode"
        v-if="wechatQrcode"
      >
        <img :src="wechatQrcode">
        <span>微信公众号</span>
      </div>
      <div class="qrcode fcc" v-if="mobileCode">
        <img :src="mobileCode">
        <span>手机网站</span>
      </div>
      <div class="logo_phone">
        <div class="logo"><img :src="siteData.logo"></div>
        <p :style="{ marginTop: siteId == 5 && '0px' }">全国统一客服热线</p>
        <div class="phone"><b>{{ siteData.phone }}</b></div>
      </div>
    </div>
    <div class="fc friend_link" v-if="Number(siteId) !== 5">
      <label>友情链接：</label>
      <a
        href="http://www.xtxh.net/xtxh"
        target="_blank"
      >中国信托业协会</a>
      <a
        href="http://www.ctpf.com.cn/"
        target="_blank"
      >中国信托业保障基金</a>
      <a
        href="https://www.cbirc.gov.cn/cn/view/pages/index/index.html"
        target="_blank"
      >中国银保监会</a>
      <a
        href="http://www.chinatrc.com.cn/index.html"
        target="_blank"
      >中国信托登记网</a>
      <a
        href="http://www.csrc.gov.cn"
        target="_blank"
      >中国证监会</a>
    </div>
    <div class="fcc copyright" :style="{
      marginTop: siteId == 5 && '20px'
    }">
      <h3 v-if="siteId == 5">关于我们</h3>
      <p><span v-if="siteId != 5">Copyright © {{ siteData.nickName }} All rights reserved </span>网站备案号：<span
          class="pointer"
          @click="beianLink"
        >{{ siteData.beian }}</span><span v-if="siteId != 5"> 工商网监</span></p>
      <p v-if="siteId == 5">优保咨询，值得信赖的保险咨询服务平台</p>
      <p v-if="siteId == 5">全国服务热线: {{ siteData.phone }}</p>
      <p v-if="siteId != 5">>以上项目信息来自于多家信托公司提供给{{ siteData.name }}的信息资料，信托公司对其提供信息的真实可靠性和完整准确性负责。投资者应仔细阅读产品的法律文件，了解产品风险和收益特征（包括系统性风险和特定产品所特有的投资风险等）。投资者应根据自身资产状况、风险承受能力选择适合自己的产品。 {{ siteData.name }}提供的宣传推介材料仅供投资者参考，不构成{{ siteData.name }}的任何推荐或投资建议。投资有风险，选择需谨慎。投资者应审慎决策、独立承担风险。</p>
      <p class="bottom" v-if="siteId == 4">仅提供咨询业务，产品售卖均来自正规信托公司</p>
      <p class="bottom" v-if="siteId == 5">仅提供专业的保险咨询服务，不涉及任何资金交易</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState(['siteData', 'siteId']),
    ...mapGetters(['cateShowObj', 'aboutId']),
    showObj () {
      try {
        let obj = {}
        let showkey = Number(this.siteId) - 1
        for (let key in this.cateShowObj) {
          obj[key] = Number(this.cateShowObj[key][showkey])
        }
        return obj
      } catch {
        return {}
      }

    },
    wechatQrcode () {
      if ([3, 4].includes(Number(this.siteId))) {
        return ''
      }
      return require(`@/assets/images/qrcode/wx-${this.siteId}.png`)
    },
    mobileCode () {
      if ([5].includes(Number(this.siteId))) return '';
      return require(`@/assets/images/qrcode/mobile-${this.siteId}.png`)
    }
  },
  methods: {
    jumpTo (path) {
      this.$router.push(path)
    },
    toProduct () {
      this.$router.push(`/product`)
    },
    toNews (tab) {
      this.$router.push(`/news?tab=${tab}`)
    },
    beianLink () {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-wrapper {
  background: #d4d4d4;
  padding: 30px 50px;
  height: 404px;
  .onlyWxCode {
    justify-content: flex-end;
    align-items: flex-start;
    .wxCode {
      margin-right: 60px;
    }
  }
  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
    min-height: 182px;
  }
  .link_area {
    dl {
      justify-content: flex-start;
      dt {
        color: #333;
        font-size: 18px;
        font-weight: bold;
        line-height: 50px;
        &:hover {
          text-decoration: underline;
          color: #be181f;
        }
      }
      dd {
        color: #333;
        font-size: 14px;
        line-height: 35px;
        font-weight: normal;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: #be181f;
        }
      }
    }
  }
  .qrcode {
    img {
      width: 120px;
    }
    span {
      font-size: 14px;
      color: #333;
      line-height: 35px;
    }
  }
  .logo_phone {
    .logo {
      img {
        width: 240px;
      }
    }
    p {
      font-size: 16px;
      color: #333;
      text-align: right;
      margin-top: 15px;
      margin-right: 10px;
    }
    .phone {
      font-size: 26px;
      color: #333;
      text-align: right;
      margin-top: 5px;
      margin-right: 10px;
      font-weight: bold;
      line-height: 35px;
    }
  }
  .friend_link {
    label,
    a {
      list-style: none;
      padding: 0 5px;
      color: #333;
      font-size: 14px !important;
      line-height: 50px;
    }
    a:hover {
      color: #fe0000;
    }
  }
  .copyright {
    font-size: 12px;
    border-top: 1px solid #5e5d5d;
    padding-top: 10px;
    h3 {
      font-size: 15px;
      font-weight: normal;
      padding: 5px 0;
    }
    p {
      line-height: 22px;
      text-align: center;
      &.bottom {
        font-size: 15px;
        margin-top: 10px;
      }
    }
  }
}
</style>