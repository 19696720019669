
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime (time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

export function validate (form) {
  const keyObj = {
    userName: '姓名',
    phoneNum: '手机号',
    veriCode: '验证码'
  }
  let regPhone = /^1[3456789]\d{9}$/
  let errMsg = ''
  for (let key in form) {
    if (!form[key]) {
      errMsg = `请输入${keyObj[key]}`
      break
    }
    if (key == 'phoneNum' && !regPhone.test(form[key])) {
      errMsg = '请输入正确的手机号'
      break;
    }
  }
  return errMsg
}

export function validateEvaluation (form) {
  const keyObj = {
    name: '姓名',
    gender: '性别',
    age: '年龄',
    budget: '预算',
    phone: '手机号',
    veriCode: '验证码'
  }
  let regPhone = /^1[3456789]\d{9}$/
  let errMsg = ''
  for (let key in form) {
    if (!form[key]) {
      if (key == 'gender' || key == 'budget') {
        errMsg = `请选择${keyObj[key]}`
        break;
      }
      errMsg = `请输入${keyObj[key]}`
      break;
    }
    if (key == 'phone' && !regPhone.test(form[key])) {
      errMsg = '请输入正确的手机号'
      break;
    }
  }
  return errMsg
}

export function isMobile () {
  const userAgent = navigator.userAgent;
  const mobileKeywords = [
    'Android',
    'iPhone',
    'iPad',
    'Windows Phone',
    'BlackBerry',
    'Opera Mini',
    'Symbian',
    'Kindle',
    'Mobile'
  ];

  const regex = new RegExp(mobileKeywords.join('|'), 'i');
  return regex.test(userAgent);
}

function getIpAddress () {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://ident.me', true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // const response = JSON.parse(xhr.responseText);
          // const ip = response.ip;
          return resolve(xhr.responseText || '')
        }
        return resolve('');
      }
    };
    xhr.send();
  })

}

const getBrowserName = () => {
  const { userAgent } = window.navigator;
  const browsers = {
    '谷歌': /chrome/i,
    '火狐': /firefox/i,
    '360': /wow64/i,
    ie: /internet explorer/i,
    edge: /edge/i,
    opera: /opera|opr/i,
    yandex: /yandex/i,
    '微信': /micromessenger/i,
    '夸克': /quark/i,
    '搜狗': /sogou/i,
    'QQ': /qq/i,
    uc: /ucbrowser/i,
    samsung: /samsungbrowser/i,
    '傲游': /maxthon/i,
    phantomjs: /phantomjs/i,
    crios: /crios/i,
    firefoxios: /fxios/i,
    edgios: /edgios/i,
    '安卓': /android/i,
    safari: /safari/i,
    ios: /(iphone|ipad|ipod)/i,
    unknown: /unknown/i,
  };

  for (const key in browsers) {
    if (browsers[key].test(userAgent.toLowerCase())) {
      return key + '浏览器';
    }
  }
  return 'unknown';
};

export async function getDeviceInfo () {
  let ipAddress = await getIpAddress()
  let obj = {
    deviceType: isMobile() ? '移动端' : 'PC端',
    ipAddress,
    browser: getBrowserName()
  }
  return obj
}